/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-spread */
import { es } from 'date-fns/locale';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { defaultStaticRanges } from 'react-date-range/dist/defaultRanges';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ItemWraper } from './style';

moment.tz.setDefault('America/Mexico_City');

function ownKeys(object, enumerableOnly) {
  const keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    let symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) {
      symbols = symbols.filter(function(sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }
    keys.push.apply(keys, symbols);
  }
  return keys;
}

function _objectSpread(target) {
  for (let i = 1; i < arguments.length; i++) {
    const source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys(Object(source), true).forEach(function(key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function(key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}

const staticRangeHandler = {
  range: {},
  isSelected: function isSelected(range) {
    const definedRange = this.range();
    return moment(range.startDate).isSame(definedRange.startDate) && moment(range.endDate).isSame(definedRange.endDate);
  },
};

function createStaticRanges(ranges) {
  return ranges.map(function(range) {
    return _objectSpread(_objectSpread({}, staticRangeHandler), range);
  });
}

const DateRangePickerOne = ({ onChange }) => {
  const [state, setState] = useState({
    datePickerInternational: null,
    dateRangePicker: {
      selection: {
        startDate: moment()
          .startOf('month')
          .toDate(),
        endDate: moment()
          .endOf('month')
          .toDate(),
        key: 'selection',
      },
    },
  });

  const handleRangeChange = which => {
    setState({
      ...state,
      dateRangePicker: {
        ...state.dateRangePicker,
        ...which,
      },
    });

    onChange({
      ...state.dateRangePicker,
      ...which,
    });
  };

  const staticRangesLabels = {
    Today: 'Hoy',
    Yesterday: 'Ayer',
    'This Week': 'Esta semana',
    'Last Week': 'Semana pasada',
    'This Month': 'Mes actual',
    'Last Month': 'Mes pasado',
    'Current Year': 'Año actual',
    'Last Year': 'Año pasado',
  };

  const { dateRangePicker } = state;

  function translateRange(dictionary) {
    return item => (dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item);
  }

  const customDefaultStaticRanges = createStaticRanges([
    {
      label: 'Current Year',
      range: function range() {
        return {
          startDate: moment()
            .startOf('year')
            .toDate(),
          endDate: moment()
            .endOf('year')
            .toDate(),
        };
      },
    },
    {
      label: 'Last Year',

      range: function range() {
        return {
          startDate: moment()
            .subtract(1, 'year')
            .startOf('year')
            .toDate(),
          endDate: moment()
            .subtract(1, 'year')
            .endOf('year')
            .toDate(),
        };
      },
    },
  ]);

  const staticRanges = [...defaultStaticRanges, ...customDefaultStaticRanges].map(translateRange(staticRangesLabels));

  return (
    <ItemWraper>
      <DateRangePicker
        onChange={handleRangeChange}
        showSelectionPreview
        moveRangeOnFirstSelection={false}
        className="PreviewArea"
        locale={es}
        staticRanges={staticRanges}
        inputRanges={[]}
        months={2}
        ranges={[dateRangePicker.selection]}
        direction="horizontal"
        initialFocusedRange={[0, 1]}
      />
    </ItemWraper>
  );
};

export { DateRangePickerOne };
