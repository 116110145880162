import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import CashCut from '../../container/Reports/CashCut';
import Sales from '../../container/Reports/Sales';
import Delays from '../../container/Reports/Delays';


const ReportRoutes = () => {
  const { userLoggedRedux } = useSelector(state => {
    return {
      userLoggedRedux: state.auth.user,
    };
  });

  return (
    <Switch>
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrders') && (
        <Route exact path="/reportes/corte" component={CashCut} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrders') && (
        <Route exact path="/reportes/ventas" component={Sales} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrders') && (
        <Route exact path="/reportes/atrasos" component={Delays} />
      )}
    </Switch>
  );
};

export default ReportRoutes;
