import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

const Users = lazy(() => import('../../container/users/Users'));
const Clients = lazy(() => import('../../container/clients/Clients'));

const UserRoutes = () => {
  const { userLoggedRedux } = useSelector(state => {
    return {
      userLoggedRedux: state.auth.user,
    };
  });

  return (
    <Switch>
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:users') && (
        <Route exact path="/usuarios" component={Users} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:clients') && (
        <Route exact path="/clientes" component={Clients} />
      )}
    </Switch>
  );
};

export default UserRoutes;
