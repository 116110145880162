/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { Auth as AmplifyAuth } from 'aws-amplify';
import axios from 'axios';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Resizer from 'react-image-file-resizer';
import env from '../config/constants';

const API = {
  endpoint: env.apiUrl,
  get: async path => {
    const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();

    const r = await axios.get(`${API.endpoint}/${path}`, {
      headers: {
        Authorization: idToken,
      },
    });
    return r;
  },
  put: async (path, fields) => {
    const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();
    const r = await axios.put(`${API.endpoint}/${path}`, fields, {
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return r;
  },
  post: async (path, fields) => {
    const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();
    const r = await axios.post(`${API.endpoint}/${path}`, fields, {
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return r;
  },
  recoverPassword: async (path, fields) => {
    const r = await axios.post(`${API.endpoint}/${path}`, fields, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return r;
  },
  delete: async path => {
    const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();

    const r = await axios.delete(`${API.endpoint}/${path}`, {
      headers: {
        Authorization: idToken,
      },
    });

    return r;
  },
};

const formatAnalyticReference = (analyticReference, maquila, statusAccredited) =>
  [analyticReference, statusAccredited ? '  ▲' : '', maquila ? '  •' : ''].join('');

const codeParse = {
  CodeMismatchException: 'El código de recuperación es incorrecto',
  UserNotFoundException: 'El usuario no existe',
  LimitExceededException: 'Has superado el limite de códigos enviados, espera unos minutos y vuelve a intentarlo',
  ExpiredCodeException: 'El código de verificación expiró',
  TooManyFailedAttemptsException:
    'Has superado el limite de intentos de inicio de sesión, espera unos minutos y vuelve a intentarlo',
  NotAuthorizedException: 'Los datos ingresados son incorrectos',
  'User account already exists': 'El nombre de usuario ya está en uso',
};

const areaVariableObs = {
  'Absorción atómica - UV': [
    'Se analizó la muestra en presentación de envase polietileno de 20L',
    'Límites máximos permisibles proporcionados por el cliente basados en la NOM-201-SSA1-2015 o NOM-127 según sea el caso',
  ],
  Cromatografía: [
    'Los valores no cuantificables o detectables en los analitos de las muestras, se reportan con el símbolo < antecediendo al valor numérico del límite de cuantificación.',
    'Se analizó en presentación de _',
  ],
  Fisicoquímicos: [
    'Carbohidratos disponibles son los que se toman en cuenta para el diseño de la tabla y el cálculo calórico.',
    'Carbohidratos disponibles = carbohidratos totales - fibra dietética',
    'Se analizó la muestra en presentación de  ',
    'Granulometria realizada con Malla No.   a petición del cliente',
    'Bebidas alcoholicas El contenido calorico considera la cantidad de alcohol  con un factor de 7 kcal/g y 29 kJ/g',
    'Temperatura de la muestra en la determinación de pH=25,3°C sin embargo el potenciómetro cuenta con un compensador de temperatura el cuál realiza la lectura de pH=25,0°C',
    'Viscosidad a 20°C con viscosimetro BROOKFIELD Modelo RVP N/S 68721, SPIN No. 6 a 20 rpm',
    'Apéndice C 1.2 grasa ácida',
    'Apéndice C 1.3 grasa alcalina',
  ],
  Microbiología: [
    '* E.coli ß- Glucoronidasa positiva en alimentos',
    '* Valor estimado',
    '< 0,3 NMP/mL = No detectable',
    '< 1,1 NMP/100mL = Ausente',
    '< 10 UFC/g = No detectable',
    '< 10 UFC/mL = No detectable',
    '< 1 UFC/mL = No detectable',
    '< 100 UFC/g = No detectable',
    '< 3,0 NMP/g = No detectable',
    'Limites establecidos por el cliente.',
    'Medio ambiente expuesto durante 15 minutos',
    'Resultado para Bacterias mesofílicas aerobias = UFC/60cm²',
    'Resultado para Bacterias mesofílicas aerobias = UFC/ambas manos',
    'Resultado para Bacterias mesofílicas aerobias = UFC/ambos guantes',
    'Salmonella = Ausente en superficie expuesta',
    'Salmonella = Ausente en superficie muestreada',
    'Superficie muestreada = 60cm² en 10mL de diluyente',
    'Superficie muestreada = 60cm² en 10mL de diluyente del que se  analizó 3.33mL para la determinación de Coliformes y E.coli.',
    'Superficie muestreada = Ambas manos en 100mL de diluyente',
    'Volumen muestreado = 500L con equipo',
  ],
  Elisa: ['Límite de detección 0.375 ng/g'],
  PCR: ['Superficie muestreada = 60cm² en 10mL de diluyente'],
};

const errorParser = code => {
  const error = codeParse[code];
  return error;
};
const businessSectorList = [
  'Acuícola',
  'Aceites y grasas',
  'Agrícola',
  'Agua y hielo',
  'Alimentación animal',
  'Alimentos preparados',
  'Alimentos varios',
  'Bebidas alcohólicas',
  'Bebidas',
  'Botanas y snacks',
  'Cárnicos y embutidos',
  'Café y té',
  'Cafeterías',
  'Cereales y harinas',
  'Confitería',
  'Conservas (mermeladas y mieles)',
  'Consultores',
  'Cosméticos',
  'Empaques',
  'Especias y sal',
  'Escuelas',
  'Establecimientos comerciales',
  'Gel antibacterial',
  'Gobiernos',
  'Helados',
  'Lácteos',
  'Laboratorios',
  'Materias primas y aditivos',
  'Panadería y galletas',
  'Privado',
  'Restaurantes',
  'Salsas, aderezos y vinagres',
  'Suplementos',
  'Tequila',
  'Tortilla/tostadas',
];

const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'MÉXICO',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

const exportToCSV = (csvData, fileName) => {
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Data');
  const colStyle = Object.keys(csvData[0]).map(el => {
    const max = csvData.reduce((w, r) => {
      if (r[el] === null || r[el] === undefined) {
        return Math.max(w, ''.length);
      }
      return Math.max(w, r[el].length);
    }, 10);
    return { wch: max };
  });

  ws['!cols'] = [...colStyle];
  XLSX.writeFile(wb, `${fileName}${fileExtension}`);
};

const resizeImage = async (file, ext) => {
  return new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(file, 800, 800, `.${ext[1]}`, 100, 0, uri => resolve(uri), 'file');
    } catch (e) {
      reject(e);
    }
  });
};

const makeDelayedHttpGetCall = (url, delay) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      API.get(url)
        .then(res => resolve(res))
        .catch(error => reject(error));
    }, delay);
  });
};

export {
  API,
  errorParser,
  countryList,
  resizeImage,
  businessSectorList,
  areaVariableObs,
  formatAnalyticReference,
  exportToCSV,
  makeDelayedHttpGetCall,
};
