import { Avatar } from 'antd';
import { Auth } from 'aws-amplify';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut } from '../../../redux/authentication/actionCreator';
import userSVG from '../../../static/img/user.svg';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { InfoWraper, UserDropDwon } from './auth-info-style';

const AuthInfo = () => {
  const dispatch = useDispatch();

  const [user, setUser] = useState();
  const SignOut = async e => {
    e.preventDefault();
    await Auth.signOut();
    dispatch(logOut());
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const res = await Auth.currentSession();
    setUser(res.idToken.payload);
  };
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img style={{ height: '50px' }} src={userSVG} alt="" />
          <figcaption>
            <Heading as="h5">{user?.firstName}</Heading>
            <p>{user?.firstname}</p>
            <p>{user?.role}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links" />
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Cerrar Sesión
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar size="large" style={{ backgroundColor: '#d6195e' }} gap={2}>
              {user?.firstName}
            </Avatar>
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
