/* eslint-disable no-useless-escape */
import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { TopMenuStyle } from './style';
import { Dropdown } from 'antd';

const determinationsRoles = [
  'Administrador',
  'Sistemas',
  'Dirección',
  'Recepción',
  'Informes',
  'Recepción limitado',
  'Facturación',
  'Ventas/Cotizaciones',
];

const TopMenu = () => {
  const { userLoggedRedux } = useSelector(state => {
    return {
      userLoggedRedux: state.auth.user,
    };
  });

  useLayoutEffect(() => {
    const active = document.querySelector('.strikingDash-top-menu a.active');
    const activeDefault = () => {
      active.classList.add('active');
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  const escapeRegExpMatch = s => {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };
  const isExactMatch = (str, match) => {
    const res = new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
    return res;
  };

  return (
    <TopMenuStyle>
      <div className="strikingDash-top-menu">
        <ul>
          {/* <li>
            <NavLink to={`${path}/orders`}> Órdenes de trabajo</NavLink>
          </li> */}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:users') && (
            <li>
              <NavLink to="/usuarios">Usuarios</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:clients') && (
            <li>
              <NavLink to="/clientes">Clientes</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:determinations') &&
            determinationsRoles.includes(userLoggedRedux?.idToken?.payload?.role) && (
              <li>
                <NavLink to="/determinaciones">Determinaciones</NavLink>
              </li>
            )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:determinationsPackages') && (
            <li>
              <NavLink to="/paquetes">Paquetes</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:maquiladoresMenu') && (
            <li>
              <NavLink to="/laboratorios">Maquiladores</NavLink>
            </li>
          )}
          {isExactMatch(userLoggedRedux?.idToken?.payload?.permissions, 'read:workOrders') && (
            <li>
              <NavLink to="/ordenes">Ordenes</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrdersByArea') && (
            <li>
              <NavLink to="/ordenes-procesos">Órdenes de trabajo</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrdersReported') && (
            <li>
              <NavLink to="/ordenes-resultados">Resultados reportados</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:adminWorkOrders') && (
            <li>
              <NavLink to="/ordenes-admin">Informes</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:maquilas') && (
            <li>
              <NavLink to="/maquilas">Maquilas</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:bitacora') && (
            <li>
              <NavLink to="/bitacora">Bitácora</NavLink>
            </li>
          )}
          {userLoggedRedux?.idToken?.payload?.permissions.includes('read:reportsMenu') && (
            <li>
              <Dropdown
                overlay={
                  <div className="dropdownMenuContainer">
                    <NavLink to="/reportes/corte">Corte diario</NavLink>
                    <NavLink to="/reportes/ventas">Ventas</NavLink>
                    {userLoggedRedux?.idToken?.payload?.permissions.includes('read:delaysReport')&&<NavLink to="/reportes/atrasos">Atrasos</NavLink>}
                  </div>
                }
                trigger={['hover']}
                placement="bottomCenter"
              >
                <div className="dropDownMenuAnchor">Reporteadores</div>
              </Dropdown>
            </li>
          )}
        </ul>
      </div>
    </TopMenuStyle>
  );
};

export default TopMenu;
