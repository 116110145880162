import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import Amplify, { Auth as AWSAuth } from 'aws-amplify';
import moment from 'moment-timezone';
import 'moment/locale/es-mx';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config, { amplifyConfig } from './config/config';
import { setNewUser, setDefaultRoute } from './redux/authentication/actionCreator';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.less';

moment.tz.setDefault('America/Mexico_City');

Amplify.configure(amplifyConfig);
const { theme } = config;

moment.locale('es');

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode, userLoggedRedux, defaultRoute } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      userLoggedRedux: state.auth.user,
      defaultRoute: state.auth.defaultRoute,
    };
  });

  const [path, setPath] = useState(window.location.pathname);
  const [routeSet, setRouteSet] = useState(false);
  // eslint-disable-next-line no-unused-vars

  const defaultRoutes = {
    Administrador: '/usuarios',
    Sistemas: '/usuarios',
    Dirección: '/ordenes-admin',
    'Analista de área': '/ordenes-procesos',
    'Supervisor de área técnica': '/ordenes-resultados',
    'Supervisor para autorizar': '/ordenes-resultados',
    Recepción: '/ordenes',
    Informes: '/ordenes',
  };

  const dispatch = useDispatch();
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  useEffect(() => {
    if (defaultRoute) {
      setRouteSet(true);
    }
  }, [defaultRoute]);

  useEffect(() => {
    if (userLoggedRedux) {
      const def = defaultRoutes[userLoggedRedux.idToken?.payload?.role];
      dispatch(setDefaultRoute(def));
    }
  }, [userLoggedRedux]);

  const getUserLogged = async () => {
    const res = await AWSAuth.currentSession();
    dispatch(setNewUser(res));
  };

  useEffect(() => {
    getUserLogged();
  }, []);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'} locale={esES}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/" component={Admin} />}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && routeSet && (
            <Redirect to={defaultRoute} />
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
