import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';

const determinationsRoles = [
  'Administrador',
  'Sistemas',
  'Dirección',
  'Recepción',
  'Informes',
  'Recepción limitado',
  'Facturación',
  'Ventas/Cotizaciones',
];

const MenuItems = ({ darkMode, topMenu, toggleCollapsed }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const { userLoggedRedux } = useSelector(state => {
    return {
      userLoggedRedux: state.auth.user,
    };
  });

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const escapeRegExpMatch = s => {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  };
  const isExactMatch = (str, match) => {
    const res = new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
    return res;
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:users') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="users"
        >
          <NavLink to="/usuarios">Usuarios</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:clients') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="clients"
        >
          <NavLink to="/clientes">Clientes</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:determinations') &&
        determinationsRoles.includes(userLoggedRedux?.idToken?.payload?.role) && (
          <Menu.Item
            onClick={() => {
              toggleCollapsed();
            }}
            icon={!topMenu && <FeatherIcon icon="user" />}
            key="determinations"
          >
            <NavLink to="/determinaciones">Determinaciones</NavLink>
          </Menu.Item>
        )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:determinationsPackages') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="determinationsPackages"
        >
          <NavLink to="/paquetes">Paquetes</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:maquiladoresMenu') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="maquiladores"
        >
          <NavLink to="/laboratorios">Maquiladores</NavLink>
        </Menu.Item>
      )}
      {isExactMatch(userLoggedRedux?.idToken?.payload?.permissions, 'read:workOrders') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="workOrders"
        >
          <NavLink to="/ordenes">Ordenes</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrdersByArea') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="workOrdersByArea"
        >
          <NavLink to="/ordenes-procesos">Órdenes de trabajo</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrdersReported') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="workOrdersReported"
        >
          <NavLink to="/ordenes-resultados">Resultados reportados</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:adminWorkOrders') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="adminWorkOrders"
        >
          <NavLink to="/ordenes-admin">Informes</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:maquilas') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="maquilas"
        >
          <NavLink to="/maquilas">Maquilas</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:maquilas') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="bitácora"
        >
          <NavLink to="/bitacora">Bitácora</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:corteDiario') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="corteDiario"
        >
          <NavLink to="/reportes/corte">Corte diario</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:salesReport') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="salesReport"
        >
          <NavLink to="/reportes/ventas">Ventas</NavLink>
        </Menu.Item>
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:delaysReport') && (
        <Menu.Item
          onClick={() => {
            toggleCollapsed();
          }}
          icon={!topMenu && <FeatherIcon icon="user" />}
          key="delaysReport"
        >
          <NavLink to="/reportes/atrasos">Atrasos</NavLink>
        </Menu.Item>
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
};

export default MenuItems;
