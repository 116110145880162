import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Popover } from '../../popup/popup';
import { DateRangePickerOne } from '../../datePicker/datePicker';
import { Button } from '../buttons';

const CalendarButtonPageHeader = ({ onChange, style, dateRange }) => {
  const content = (
    <>
      <DateRangePickerOne onChange={onChange} />
    </>
  );

  return (
    <Popover placement="bottomRight" title="Filtrar por fecha" content={content} action="hover">
      <Button style={{ height: '40px', ...style }} type="white">
        <FeatherIcon icon="calendar" size={14} />
        {dateRange}
      </Button>
    </Popover>
  );
};

export { CalendarButtonPageHeader };
