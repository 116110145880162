import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

const Orders = lazy(() => import('../../container/orders/Orders'));
const AdminOrders = lazy(() => import('../../container/orders/AdminOrders'));
const Determinations = lazy(() => import('../../container/determinations/Determinations'));
const Packages = lazy(() => import('../../container/packages/Packages'));
const OrderProcess = lazy(() => import('../../container/orderProcess/OrderProcess'));
const OrderResults = lazy(() => import('../../container/resultsReported/ResultsReported'));
const Maquilas = lazy(() => import('../../container/maquila/Maquilas'));
const MaquilaOrders = lazy(() => import('../../container/maquilaOrders/MaquilaOrders'));
const LogBook = lazy(() => import('../../container/LogBook/LogBook'));

const determinationsRoles = [
  'Administrador',
  'Sistemas',
  'Dirección',
  'Recepción',
  'Informes',
  'Recepción limitado',
  'Facturación',
  'Ventas/Cotizaciones',
];
const OrderRoutes = () => {
  const { userLoggedRedux } = useSelector(state => {
    return {
      userLoggedRedux: state.auth.user,
    };
  });

  return (
    <Switch>
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrders') && (
        <Route exact path="/ordenes" component={Orders} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrdersByArea') && (
        <Route exact path="/ordenes-procesos" component={OrderProcess} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:workOrderReported') && (
        <Route exact path="/ordenes-resultados" component={OrderResults} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:adminWorkOrders') && (
        <Route exact path="/ordenes-admin" component={AdminOrders} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:determinations') &&
        determinationsRoles.includes(userLoggedRedux?.idToken?.payload?.role) && (
          <Route exact path="/determinaciones" component={Determinations} />
        )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:determinationsPackages') && (
        <Route exact path="/paquetes" component={Packages} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:maquiladores') && (
        <Route exact path="/laboratorios" component={Maquilas} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:maquilas') && (
        <Route exact path="/maquilas" component={MaquilaOrders} />
      )}
      {userLoggedRedux?.idToken?.payload?.permissions.includes('read:maquilas') && (
        <Route exact path="/bitacora" component={LogBook} />
      )}
    </Switch>
  );
};

export default OrderRoutes;
