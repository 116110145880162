/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Col, message, Row, Spin, Table, Popover, Input, Select, notification, Modal, Drawer, Space } from 'antd';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../components/buttons/buttons';
import { CalendarButtonPageHeader } from '../../components/buttons/calendar-button/calendar-button';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { API, errorParser, makeDelayedHttpGetCall } from '../../utility/utils';
import { UserTableStyleWrapper } from '../pages/style';
import { CardToolbox, Main, TableWrapper } from '../styled';
import _ from 'lodash';
import CommentsLog from '../../components/commentsLog/CommentsLog';
import { AutoComplete } from '../../components/autoComplete/autoComplete';

moment.tz.setDefault('America/Mexico_City');

const CashCut = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [ReportsTableData, setReportsTableData] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [searchWords, setSearchWords] = useState('');
  const [agents, setAgents] = useState([]);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [showCommentsLog, setShowCommentsLog] = useState(false);
  const [showReviewedModal, setShowReviewedModal] = useState(false);
  const [summary, setSummary] = useState(null);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [selectedNewClientFilter, setSelectedNewClientFilter] = useState(null);
  const [summaryDrawerOpen, setSummaryDrawerOpen] = useState(false);
  const [dateRange, setDateRange] = useState(`${moment().format('DD/MM/YYYY')}-${moment().format('DD/MM/YYYY')}`);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [editedValue, setEditedValue] = useState({});

  const paymentTypeList = [
    'ABONADO',
    'CREDITO',
    'PAGO_CONTADO',
    'TRANSFERENCIA',
    'EFECTIVO_FACTURA',
    'EFECTIVO_RECIBO',
    // 'EFECTIVO',
    'CHEQUE',
    'TARJETA',
    'COT_SALDO_A_FAVOR',
  ];

  const summaryColumns = [
    { title: 'Total de Muestras', key: 'totalOrders', dataIndex: 'totalOrders' },
    { title: 'Total de Cotizaciones', key: 'totalQuotes', dataIndex: 'totalQuotes' },
    { title: 'Cotizaciones a Crédito', key: 'totalCredito', dataIndex: 'totalCredito' },
    { title: 'Crédito', key: 'credito', dataIndex: 'credito' },
    // { title: 'Cotizaciones Efectivo', key: 'totalEfectivo', dataIndex: 'totalEfectivo' },
    // { title: 'Efectivo', key: 'efectivo', dataIndex: 'efectivo' },
    { title: 'Cotizaciones Efectivo Factura', key: 'totalEfectivoFactura', dataIndex: 'totalEfectivoFactura' },
    { title: 'Efectivo Factura', key: 'efectivoFactura', dataIndex: 'efectivoFactura' },
    { title: 'Cotizaciones Efectivo Recibo', key: 'totalEfectivoRecibo', dataIndex: 'totalEfectivoRecibo' },
    { title: 'Efectivo Recibo', key: 'efectivoRecibo', dataIndex: 'efectivoRecibo' },
    { title: 'Cotizaciones por Transferencia', key: 'totalTransferencia', dataIndex: 'totalTransferencia' },
    { title: 'Transferencia', key: 'transferencia', dataIndex: 'transferencia' },
    { title: 'Número de Cheques', key: 'totalCheque', dataIndex: 'totalCheque' },
    { title: 'Cheque', key: 'cheque', dataIndex: 'cheque' },
    { title: 'Cotizaciones Abonado', key: 'totalAbonado', dataIndex: 'totalAbonado' },
    { title: 'Abonado', key: 'abonado', dataIndex: 'abonado' },
    { title: 'Cotizaciones con Saldo A Favor', key: 'totalSaldoAFavor', dataIndex: 'totalSaldoAFavor' },
    { title: 'Saldo A Favor', key: 'saldoAFavor', dataIndex: 'saldoAFavor' },
    { title: 'Movimientos de Contado', key: 'totalContado', dataIndex: 'totalContado' },
    { title: 'Contado', key: 'contado', dataIndex: 'contado' },
    { title: 'Cotizaciones con Tarjeta', key: 'totalTarjeta', dataIndex: 'totalTarjeta' },
    { title: 'Tarjeta', key: 'tarjeta', dataIndex: 'tarjeta' },
    { title: 'Clientes Nuevos', key: 'newClients', dataIndex: 'newClients' },
    { title: 'Porcentaje Contado', key: 'percentageContado', dataIndex: 'percentageContado' },
    { title: 'Importe Abonado', key: 'importeAbonado', dataIndex: 'importeAbonado' },
    { title: 'Saldo Abonado', key: 'balanceAbonado', dataIndex: 'balanceAbonado' },
    { title: 'Porcentaje Credito', key: 'percentageCredito', dataIndex: 'percentageCredito' },
    { title: 'Total Ingreso', key: 'totalIncome', dataIndex: 'totalIncome' },
    { title: 'Monto Total', key: 'totalAmount', dataIndex: 'totalAmount' },
  ];

  const paymentListDisplayValue = {
    ABONADO: 'Abonado',
    CREDITO: 'Crédito',
    PAGO_CONTADO: 'Contado',
    TRANSFERENCIA: 'Transferencia',
    CHEQUE: 'Cheque',
    TARJETA: 'Tarjeta',
    COT_SALDO_A_FAVOR: 'Saldo a Favor',
    EFECTIVO: 'Efectivo',
    EFECTIVO_FACTURA: 'Efectivo Factura',
    EFECTIVO_RECIBO: 'Efectivo Recibo',
  };

  const [date, setDate] = useState({
    start: moment()
      .startOf('day')
      .format('X'),
    end: moment()
      .endOf('day')
      .format('X'),
  });
  useEffect(() => {
    fetchReports();
    fetchPrepareData();
  }, []);

  const fetchReports = async () => {
    setFetchingData(true);
    const res = await API.get(
      `reports/corte-diario?&startDate=${date.start}&endDate=${
        date.end
      }&search=${searchWords}&newClients=${selectedNewClientFilter}&paymentMethod=${selectedPaymentMethod ||
        ''}&productTypeId=${selectedProductType || ''}`,
    );
    const arr = [];
    setSummary(res.data.data.summary);

    res.data.data.rows.forEach(u => {
      u.childrenArray = u.children?.map(c => ({
        folio: c.folio,
        client: c.client,
        status: parseStatus[c.status],
        product: c.product,
        deliveryDateClient: c.deliveryDateClient,
        deliveryDateAnalist: c.deliveryDateAnalist,
        deliveredToClientTimestamp: c.deliveredToClientTimestamp,
        alteredDeliveryDateClient: c.alteredDeliveryDateClient,
        alteredDeliveryDateAnalist: c.alteredDeliveryDateAnalist,
      }));
      delete u.children;
      arr.push({
        ...u,
        key: uuidv4(),
        observations: (
          <Button
            onClick={() => {
              setShowCommentsLog(true);
              setSelectedOrderId(u.workOrderId);
            }}
            type="primary"
          >
            Ver
          </Button>
        ),
        invoice: u.invoice || 'N/A',
        salesAgent: u.salesAgent || 'N/A',
        paymentMethod: u.paymentMethod || 'N/A',
        balancePaid: u.balancePaid || 0,
        amountPaid: u.amountPaid || 0,
        isNewClient: u.isNewClient === null ? 'N/A' : u.isNewClient,
        action: (
          <div className="table-actions orders">
            <>
              {!u.reviewedCorteDiario ? (
                <Button
                  onClick={() => {
                    setEditedValue({ key: 'reviewedCorteDiario', value: true });
                    setSelectedOrderId(u.workOrderId);
                    setShowReviewedModal(true);
                  }}
                  className="btn-icon"
                  type="info"
                  to="#"
                  shape="circle"
                >
                  <FeatherIcon icon="check-circle" size={16} />
                </Button>
              ) : (
                <div style={{ display: 'flex', gap: '5px' }}>
                  <FeatherIcon icon="check" size={16} color="green" />
                  <p style={{ color: 'green', fontSize: 12, margin: 0 }}>Revisado</p>
                </div>
              )}
            </>
          </div>
        ),
      });
    });

    setReportsTableData(arr);

    setFetchingData(false);
  };

  const orderTableColumns = [
    {
      title: 'Indice',
      dataIndex: 'idx',
      key: 'idx',
    },
    {
      title: 'Fecha de ingreso',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
    },
    {
      title: 'Total de muestras',
      dataIndex: 'totalWorkOrders',
      key: 'totalWorkOrders',
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Cotización',
      dataIndex: 'quote',
      key: 'quote',
      render: (text, record) =>
        !record.reviewedCorteDiario ? renderPopover(record.workOrderId, text, 'quote') : <div>{text}</div>,
    },

    {
      title: 'Tipo de pago',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (text, record) =>
        !record.reviewedCorteDiario ? (
          renderPopover(record.workOrderId, text, 'paymentMethod', true)
        ) : (
          <div>{paymentListDisplayValue[text]}</div>
        ),
    },
    {
      title: 'Importe Abonado',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      render: (text, record) =>
        !record.reviewedCorteDiario ? renderPopover(record.workOrderId, text, 'amountPaid') : <div>{text}</div>,
    },
    {
      title: 'Saldo Abonado',
      dataIndex: 'balancePaid',
      key: 'balancePaid',
      render: (text, record) =>
        !record.reviewedCorteDiario ? renderPopover(record.workOrderId, text, 'balancePaid') : <div>{text}</div>,
    },
    {
      title: 'Importe',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) =>
        !record.reviewedCorteDiario ? renderPopover(record.workOrderId, text, 'amount') : <div>{text}</div>,
    },
    {
      title: 'Factura',
      dataIndex: 'invoice',
      key: 'invoice',
      render: (text, record) =>
        !record.reviewedCorteDiario ? renderPopover(record.workOrderId, text, 'invoice') : <div>{text}</div>,
    },
    {
      title: 'Agente de ventas',
      dataIndex: 'salesAgent',
      key: 'salesAgent',
      render: (text, record) =>
        !record.reviewedCorteDiario ? renderPopover(record.workOrderId, text, 'salesAgent', true) : <div>{text}</div>,
    },
    {
      title: 'Cliente nuevo',
      dataIndex: 'isNewClient',
      key: 'isNewClient',
      render: (text, record) =>
        !record.reviewedCorteDiario ? (
          renderPopover(record.workOrderId, text, 'newClient', true)
        ) : (
          <div>{text === null ? 'N/A' : text === true ? 'SI' : 'NO'}</div>
        ),
    },
    {
      title: 'Observaciones',
      dataIndex: 'observations',
      key: 'observations',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const fetchPrepareData = async () => {
    const res2 = await API.get('work-order/prepare-create');
    setAgents(res2.data.data.salesAgentList);
    setProductTypes(res2.data.data.productTypeList);
  };

  const translateValues = {
    folio: 'Folio',
    client: 'Cliente',
    status: 'Estado',
    deliveryDateClient: 'Fecha de entrega cliente',
    deliveryDateAnalist: 'Fecha de entrega analista',
    deliveredToClientTimestamp: 'Entregado al cliente',
    product: 'Producto',
    areas: 'Áreas',
    alteredDeliveryDateClient: 'Fecha de entrega alterada cliente',
    alteredDeliveryDateAnalist: 'Fecha de entrega alterada analista',
  };

  const parseStatus = {
    PENDING: 'Pendiente',
    IN_PROGRESS: 'En progreso',
    DELIVERED_BY_ANALISTS: 'Entregado por los analistas',
    AUTHORIZED: 'Autorizada',
    DELIVERED_TO_CLIENT: 'Entregada a cliente',
  };

  // FIXME: check the actual field name with Erick
  const handleEdit = async () => {
    setLoadingEdit(true);
    try {
      const { key, value } = editedValue;
      const stringKeys = ['paymentMethod', 'salesAgent', 'invoice', 'quote'];
      const numKeys = ['amount', 'amountPaid', 'balancePaid'];
      const boolKeys = ['newClient', 'reviewedCorteDiario'];
      const payload = {
        fieldType: key,
        valueStr: stringKeys.includes(key) ? value : null,
        valueNum: numKeys.includes(key) ? value && value.replace(/^\$/, '') : null,
        valueBool: boolKeys.includes(key) ? value : null,
        workOrderId: selectedOrderId,
      };
      console.log(payload);
      const res = await API.put(`reports/corte-diario/update`, payload);
      setLoadingEdit(false);
      fetchReports();
      setShowReviewedModal(false);
      setSelectedOrderId(null);
      setEditedValue({});
      setShowCommentsLog(false);
      notification.success({ message: 'Hecho', description: 'Se han actualizado los datos' });
    } catch (e) {
      setLoadingEdit(false);
      notification.error({ message: 'Error', description: errorParser(e.response.data.error) });
    }
  };

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      const res = await API.get(`reports/corte-diario/export?startDate=${date.start}&endDate=${date.end}`);
      window.open(res.data.data.s3Key);
    } catch (e) {
      console.log(e);
    }
    setLoadingExport(false);
  };

  const { Option } = Select;
  // amount, paymentMethod, salesAgent, invoice, quote, newClient, reviewedCorteDiario
  const renderPopover = (workOrderId, text, key, select = false) => (
    <Popover
      className="popover-reports"
      content={
        <div>
          {select ? (
            <Select
              className="popover-reports"
              defaultValue={text}
              style={{ width: '12rem' }}
              onChange={value => {
                setEditedValue({ value, key });
              }}
            >
              {key === 'salesAgent' ? (
                agents.map(agent => (
                  <Option value={agent.id} key={agent.id}>
                    {agent.name}
                  </Option>
                ))
              ) : key === 'newClient' ? (
                <>
                  <Option value key="true">
                    Si
                  </Option>
                  <Option value={false} key="false">
                    No
                  </Option>
                  <Option value={undefined} key="undefined">
                    N/A
                  </Option>
                </>
              ) : key === 'paymentMethod' ? (
                paymentTypeList.map(type => (
                  <Option value={type} key={type}>
                    {paymentListDisplayValue[type]}
                  </Option>
                ))
              ) : (
                <Option value={text} key={text}>
                  {text}
                </Option>
              )}
            </Select>
          ) : (
            <Input
              className="popover-reports"
              prefix={key === 'amount' ? '$' : null}
              placeholder="Importe"
              defaultValue={key === 'amount' ? text.substring(1) : text}
              onChange={e => {
                setEditedValue({ value: e.target.value, key });
              }}
            />
          )}
          <Button
            className="btn-add_new"
            size="default"
            type="primary"
            loading={loadingEdit}
            disabled={loadingEdit}
            style={{ marginTop: 10, display: 'flex' }}
            onClick={handleEdit}
          >
            Guardar
          </Button>
        </div>
      }
      trigger="click"
      getPopupContainer={trigger => trigger.parentNode}
      overlayStyle={{ boxShadow: '0px 4px 20px rgba(0,0,0,0.15)', padding: 0 }}
    >
      <div
        onClick={() => {
          setSelectedOrderId(workOrderId);
        }}
        className="popover-text-td"
      >
        {key === 'paymentMethod'
          ? paymentListDisplayValue[text]
          : text === true
          ? 'SI'
          : text === false
          ? 'NO'
          : text || 'N/A'}
      </div>
    </Popover>
  );

  useEffect(() => {
    fetchReports();
  }, [currentPage, searchWords, date, selectedNewClientFilter, selectedPaymentMethod, selectedProductType]);

  const handleDateChange = newDate => {
    setDate({
      start: moment(newDate.selection.startDate)
        .startOf('day')
        .format('X'),
      end: moment(newDate.selection.endDate)
        .endOf('day')
        .format('X'),
    });

    const temp = `${moment(newDate.selection.startDate)
      .startOf('day')
      .format('DD/MM/YYYY')}-${moment(newDate.selection.endDate)
      .startOf('day')
      .format('DD/MM/YYYY')}`;
    setDateRange(temp);

    setCurrentPage(1);
  };

  const handleSearch = _.debounce(value => {
    setSearchWords(value);
    setCurrentPage(1);
  }, 500);

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Corte diario"
          subTitle={
            <div
              className="ordersContentBar"
              style={{ display: 'flex', alignItems: 'center', gap: 10, paddingTop: 20, overflowY: 'hidden' }}
            >
              <CalendarButtonPageHeader dateRange={dateRange} onChange={handleDateChange} style={{ marginRight: 20 }} />
              <AutoComplete onSearch={handleSearch} placeholder="Buscar ..." width="22rem" patterns />
              <Col span={4}>
                <p
                  style={{
                    fontSize: 10,
                    position: 'absolute',
                    margin: 0,
                    top: '-18px',
                    zIndex: 1000,
                    color: '#3b3b3b',
                  }}
                >
                  Metodo de pago
                </p>
                <Select
                  value={selectedPaymentMethod}
                  placeholder="Método de pago"
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  onChange={value => {
                    setSelectedPaymentMethod(value);
                  }}
                >
                  {paymentTypeList.map(type => (
                    <Option value={type} key={type}>
                      {paymentListDisplayValue[type]}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={4}>
                <p
                  style={{
                    fontSize: 10,
                    position: 'absolute',
                    margin: 0,
                    top: '-18px',
                    zIndex: 1000,
                    color: '#3b3b3b',
                  }}
                >
                  Clientes nuevos
                </p>
                <Select
                  value={selectedNewClientFilter}
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  placeholder="Clientes nuevos"
                  onChange={value => {
                    setSelectedNewClientFilter(value);
                  }}
                >
                  <Option value key="true">
                    Si
                  </Option>
                  <Option value={false} key="false">
                    No
                  </Option>
                  <Option value={null} key="nul">
                    Sin definir
                  </Option>
                </Select>
              </Col>
              {ReportsTableData.length > 0 && (
                <div style={{ display: 'flex', gap: 10 }}>
                  <Button
                    className="btn-add_new"
                    size="default"
                    type="primary"
                    key="1"
                    onClick={() => {
                      setSummaryDrawerOpen(true);
                    }}
                  >
                    Ver resumen
                  </Button>
                  <Button
                    className="btn-add_new"
                    size="default"
                    type="primary"
                    key="2"
                    loading={loadingExport}
                    disabled={loadingExport}
                    onClick={handleExport}
                  >
                    Exportar
                  </Button>
                </div>
              )}
               {selectedNewClientFilter !== null || selectedPaymentMethod || selectedProductType ? (
                <Button
                  className="btn-add_new"
                  size="default"
                  type="ghost"
                  style={{ color: '#000' }}
                  onClick={() => {
                    setSelectedNewClientFilter(null);
                    setSelectedPaymentMethod(null);
                    setSelectedProductType(null);
                    setCurrentPage(1);
                  }}
                >
                  Limpiar filtros
                </Button>
              ) : null}
            </div>
          }
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <TableWrapper>
                  {fetchingData === false ? (
                    <Table
                      dataSource={ReportsTableData}
                      columns={orderTableColumns}
                      showSizeChanger={false}
                      locale={{ emptyText: 'No se encontraron resultados' }}
                      expandable={{
                        expandedRowRender: record => (
                          <div style={{display:"flex",flexDirection:"column",flex:1, gap:10}}>
                            <div style={{flex:1,flexDirection:"row", gap:20, display:"flex", backgroundColor: '#d61a5e', borderRadius:10, padding:"0 10px"}}>
                             {Object.keys(record.childrenArray[0]).map((key) => (
                               <div style={{flex:1, display:"flex", alignItems:"center",}}>
                               <label style={{fontSize:14,color: '#fff'}}>{translateValues[key]}</label>
                              </div>
                             ))}
                             </div>
                             <div style={{padding:"0 10px"}}> 
                            {record.childrenArray.map(el => (
                              <div
                                style={{
                                  flexDirection: 'row',
                                  display: 'flex',
                                  gap: 20,
                                }}
                              >
                                {Object.entries(el).map(([key, value]) => (
                                  <div style={{flex:1,}}>
                                    <p style={{margin:0,}}>
                                      {[
                                        'deliveryDateClient',
                                        'deliveryDateAnalist',
                                        'alteredDeliveryDateClient',
                                        'alteredDeliveryDateAnalist',
                                        'deliveredToClientTimestamp'
                                      ].includes(key)
                                        ? value && moment(value).format('DD/MM/YYYY')
                                        : value}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            ))}
                            </div>
                          </div>
                        ),
                        rowExpandable: record => record.childrenArray,
                      }}
                      pagination={{ hideOnSinglePage: true, pageSize: 9000 }}
                    />
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Spin size="large" />
                    </div>
                  )}
                </TableWrapper>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
        {showCommentsLog && (
          <CommentsLog
            entityId={selectedOrderId}
            entity="WORK_ORDER"
            handleClose={() => {
              setSelectedOrderId(null);
              setShowCommentsLog(false);
            }}
          />
        )}
      </Main>
      <Modal
        footer={[
          <Button key="cancel" onClick={() => setShowReviewedModal(false)}>
            Cerrar
          </Button>,
          <Button loading={loadingEdit} disabled={loadingEdit} key="submit" onClick={handleEdit} type="primary">
            Enviar
          </Button>,
        ]}
        title="Marcar como revisado"
        visible={showReviewedModal}
        onCancel={() => setShowReviewedModal(false)}
      >
        <div>
          <h3 style={{ textAlign: 'center' }}>¿Desea marcar esta órden como revisada?</h3>
        </div>
      </Modal>
      <Drawer
        title="Resumen"
        placement="right"
        width="40%"
        onClose={() => setSummaryDrawerOpen(false)}
        visible={summaryDrawerOpen}
        extra={
          <Space>
            <Button onClick={() => setSummaryDrawerOpen(false)}>Cerrar</Button>
          </Space>
        }
      >
        {summary && Object.keys(summary).length > 0 && (
          <table>
            <thead>
              <tr style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                <th style={{ fontWeight: 'bold', padding: '10px' }}>Nombre</th>
                <th style={{ fontWeight: 'bold', padding: '10px' }}>Valor</th>
              </tr>
            </thead>
            {summary &&
              summaryColumns
                .filter(column => summary[column.key] !== undefined)
                .map((column, index) => (
                  <tr
                    key={column.key}
                    style={{ backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#e9ecef', padding: '10px' }}
                  >
                    <td style={{ fontWeight: 'bold', padding: '10px' }}>{column.title}</td>
                    <td style={{ padding: '10px', textAlign: 'right' }}>{summary[column.key]}</td>
                  </tr>
                ))}
          </table>
        )}
      </Drawer>
    </>
  );
};

export default CashCut;
