import React from 'react';
import { Row } from 'antd';
import { Aside, Content, LoginForm } from './overview/style';
import Heading from '../../../components/heading/heading';
import logo from '../../../static/img/logo.png';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
        <Aside>
          <div className="auth-side-content">
            <Content className="login-content">
              <img className="login-logo" src={logo} alt="" />
              <br />
              <br />
              <Heading as="h1" className="white login-header">
                Sistema DESU Operadora SA. de CV.
              </Heading>
            </Content>
          </div>
        </Aside>

        <div className="login-form">
          <LoginForm style={{ paddingTop: 0 }}>
            <WraperContent />
          </LoginForm>
        </div>
      </Row>
    );
  };
};

export default AuthLayout;
